body {
    overflow-y: scroll;
}

.item-detail-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.item-detail-row-container {
    display: grid;
    grid-template-columns: 0.26fr 0.74fr;
    margin-left: 10px;
}

.item-detail-row-container > span:first-child,
.item-detail-multiline-cell > span:first-child {
    font-weight: bold;
}

.spacing-y-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.item-detail-multiline-cell {
    display: grid;
    grid-template-columns: 0.2fr 0.8fr;
}
